<template>
  <section>
    <b-container fluid>
      <b-row>
        <b-col xl="8" lg="8" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('first-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Salon Listesi</h4>
                  <b-button
                      variant="flat-primary"
                      class="text-white float-right bg-primary bg-lighten-2 border-0 card-header-btn"
                      @click="isNewCari = true"
                      v-b-modal.salon-ekle
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                  >
                    Salon Ekle
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5">
              <thead>
              <tr>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >ID
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Salon Adı
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Salon Türü
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                />
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in salonListesi" :key="item.salonID">
                <td style="width: 5%">
                  {{ item.salonID }}
                </td>
                <td style="width: 40%">
                  {{ item.salonAdi }}
                </td>
                <td style="width: 20%">
                  {{ item.turAdi }}
                </td>
                <td style="width: 35%">
                  <b-row class="d-flex justify-content-end">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="btn-icon rounded-circle mr-50"
                        size="sm"
                        v-b-modal.salon-edit
                        @click="updateStepOne(item,'salon')"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="btn-icon rounded-circle mr-50"
                        size="sm"
                        @click="salonSil(item.salonID)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="12">
                <p class="text-muted">Toplam {{ salonToplamKayit }} Kayıt bulundu</p>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
        <b-col xl="4" lg="4" cols="12">
          <b-card-actions
              class="list-card"
              ref="stok-listesi"
              @refresh="refreshStop('second-card')"
          >
            <b-row class="mt-50">
              <div class="card-header-row" :style="{backgroundColor: $store.getters.COLORS_GET.cardTitle}">
                <b-col cols="12">
                  <h4 class="text-white d-inline-block card-title">Salon Türü Listesi</h4>
                  <b-button
                      variant="flat-primary"
                      class="text-white float-right bg-primary bg-lighten-2 border-0 card-header-btn"
                      v-b-modal.salon-turu
                      @click="isNewCari = true"
                      :style="{backgroundColor:$store.getters.COLORS_GET.bgInfo+'!important', border:'none'}"
                  >
                    Salon Türü Ekle
                  </b-button>
                </b-col>
              </div>
            </b-row>
            <table class="table table-hover table-sm mt-5">
              <thead>
              <tr>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                >Tür Adı
                </th>
                <th
                    :style="{backgroundColor: $store.getters.COLORS_GET.tableTitle +'!important',color:'white!important'}"
                    scope="col"
                />
              </tr>
              </thead>
              <tbody>
              <tr v-for="item in salonTurleri" :key="item.turID">
                <td style="width: 40%">
                  {{ item.turAdi }}
                </td>
                <td style="width: 35%">
                  <b-row class="d-flex justify-content-end">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="warning"
                        class="btn-icon rounded-circle mr-50"
                        size="sm"
                        @click="updateStepOne(item,'tur')"
                        v-b-modal.salon-turu-edit
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgWarning+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen"/>
                    </b-button>
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="btn-icon rounded-circle mr-50"
                        size="sm"
                        @click="turSil(item.salonTurID)"
                        :style="{backgroundColor:$store.getters.COLORS_GET.bgDanger+'!important',border:'none'}"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash-can"/>
                    </b-button>
                  </b-row>
                </td>
              </tr>
              </tbody>
            </table>
            <hr/>
            <b-row class="d-flex align-items-center mt-1">
              <b-col cols="12">
                <p class="text-muted">Toplam {{ salonTuruToplamKayit }} Kayıt bulundu</p>
              </b-col>
            </b-row>
          </b-card-actions>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="salon-turu"
        centered
        title="Salon Türü Ekle"
        ok-title="Ekle"
        cancel-title="Vazgeç"
        @ok="turEkle"
        @cancel="tur.turAdi = ''"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Tür Adı"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="fa-solid fa-torii-gate"/>
              </b-input-group-prepend>
              <b-form-input
                  v-model="tur.turAdi"
                  autocomplete="off"
                  @keyup.enter="turEkle()"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="salon-turu-edit"
        centered
        title="Türü Düzenle"
        ok-title="Güncelle"
        cancel-title="Vazgeç"
        @ok="updateStepSecond('tur')"
        @cancel="update.tur = {}"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Tür Adı"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="fa-solid fa-torii-gate"/>
              </b-input-group-prepend>
              <b-form-input
                  v-model="update.tur.turAdi"
                  autocomplete="off"
                  @keyup.enter="turEkle()"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="salon-ekle"
        centered
        title="Salon Ekle"
        ok-title="Ekle"
        cancel-title="Vazgeç"
        @ok="salonKaydet"
        @cancel="salon.salonAdi = '',salon.salonTur = ''"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Salon Adı"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="fa-solid fa-torii-gate"/>
              </b-input-group-prepend>
              <b-form-input
                  v-model="salon.salonAdi"
                  autocomplete="off"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Salon Türü"
          >
            <model-list-select
                class=""
                v-model="salon.salonTur"
                :list="salonTurleri"
                option-value="salonTurID"
                option-text="turAdi"
                placeholder="Salon Türünü Seçiniz"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="salon-edit"
        centered
        title="Salon Düzenle"
        ok-title="Güncelle"
        cancel-title="Vazgeç"
        @ok="updateStepSecond('salon')"
        @cancel="update.salon = {}"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
              label="Salon Adı"
          >
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <font-awesome-icon icon="fa-solid fa-torii-gate"/>
              </b-input-group-prepend>
              <b-form-input
                  v-model="update.salon.salonAdi"
                  autocomplete="off"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
              label="Salon Türü"
          >
            <model-list-select
                class=""
                v-model="update.salon.salonTurID"
                :list="salonTurleri"
                option-value="salonTurID"
                option-text="turAdi"
                placeholder="Salon Türünü Seçiniz"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>

<script>
import {
  BContainer,
  BModal,
  BFormSelect,
  BInputGroupAppend,
  BPagination,
  VBTooltip,
  BBadge,
  BTable,
  BRow, BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BForm,
  BButton,
  BCardHeader,
  BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select/dist/VueSearchSelect.common'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import vMultiselectListbox from 'vue-multiselect-listbox-v2'
import 'vue-multiselect-listbox-v2/dist/vue-multi-select-listbox.css'
import 'vue-multiselect-listbox-v2/src/scss/vue-multi-select-listbox.scss'

export default {
  components: {
    BContainer,
    vMultiselectListbox,
    BModal,
    BFormSelect,
    BInputGroupAppend,
    BPagination,
    BBadge,
    BTable,
    BListGroup,
    BListGroupItem,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BForm,
    BButton,
    BRow,
    BCol,
    BCardHeader,
    BCardActions,
    ModelListSelect,
  },
  data() {
    return {
      salonToplamKayit: '',
      salonTuruToplamKayit: '',
      tur: {
        turAdi: '',
      },
      salon: {
        salonAdi: '',
        salonTur: '',
      },
      update: {
        salon: {},
        tur: {},
      },
    }
  },
  computed: {
    salonListesi() {
      return store.getters.SALON_LISTESI_GET
    },
    salonTurleri() {
      return Object.values(store.getters.SALON_TURU_LISTESI_GET)
    },
  },
  methods: {
    salonSil(id) {
      store.dispatch('salonRemove', id)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              store.dispatch('salonListesi')
                  .then(res => {
                    const data = { ...res }
                    delete data.userToken
                    this.salonToplamKayit = Object.keys(data).length
                  })
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    turSil(id) {
      store.dispatch('salonTurRemove', id)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              store.dispatch('salonTuruListesi')
                  .then(res => {
                    this.salonTuruToplamKayit = Object.keys(res.liste).length
                  })
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    turEkle() {
      store.dispatch('salonTurKayit', this.tur.turAdi)
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.tur.turAdi = ''
              this.$bvModal.hide('salon-turu')
              store.dispatch('salonTuruListesi')
                  .then(res => {
                    const liste = { ...res }
                    delete liste.userToken
                    this.salonTuruToplamKayit = Object.keys(liste).length
                  })
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    salonKaydet() {
      store.dispatch('salonKaydet', {
        salonAdi: this.salon.salonAdi,
        salonTurID: this.salon.salonTur,
      })
          .then((res, position = 'bottom-right') => {
            if (res.result.status == 200) {
              this.salon.salonTur = ''
              this.salon.salonAdi = ''
              store.dispatch('salonListesi')
                  .then(res => {
                    const liste = { ...res }
                    delete liste.userToken
                    this.salonToplamKayit = Object.keys(liste).length
                  })
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Başarılı',
                    text: mesaj,
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                }, { position })
              }
            } else {
              const mesaj = store.getters.notificationSettings(res)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            }
          })
          .catch(err => {
            const mesaj = store.getters.notificationSettings(err)
            if (mesaj) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Hata!',
                  text: mesaj,
                  icon: 'ThumbsDownIcon',
                  variant: 'danger',
                },
              })
            }
          })
    },
    updateStepOne(item, way) {
      way == 'salon' ? this.update.salon = { ...item } : this.update.tur = { ...item }
    },
    updateStepSecond(way) {
      if (way == 'salon') {
        store.dispatch('salonUpdate', {
          salonTurID: this.update.salon.salonTurID,
          salonID: this.update.salon.salonID,
          salonAdi: this.update.salon.salonAdi,
        })
            .then((res, position = 'bottom-right') => {
              if (res.result.status == 200) {
                this.update.salon = {}
                store.dispatch('salonListesi')
                    .then(res => {
                      const liste = { ...res }
                      delete liste.userToken
                      this.salonToplamKayit = Object.keys(liste).length
                    })
                const mesaj = store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  }, { position })
                }
              } else {
                const mesaj = store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            })
            .catch(err => {
              const mesaj = store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      } else {
        store.dispatch('salonTurUpdate', {
          salonTurID: this.update.tur.salonTurID,
          turAdi: this.update.tur.turAdi,
        })
            .then((res, position = 'bottom-right') => {
              if (res.result.status == 200) {
                this.update.tur = {}
                store.dispatch('salonTuruListesi')
                    .then(res => {
                      this.salonTuruToplamKayit = Object.keys(res.liste).length
                    })
                const mesaj = store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Başarılı',
                      text: mesaj,
                      icon: 'ThumbsUpIcon',
                      variant: 'success',
                    },
                  }, { position })
                }
              } else {
                const mesaj = store.getters.notificationSettings(res)
                if (mesaj) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Hata!',
                      text: mesaj,
                      icon: 'ThumbsDownIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            })
            .catch(err => {
              const mesaj = store.getters.notificationSettings(err)
              if (mesaj) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Hata!',
                    text: mesaj,
                    icon: 'ThumbsDownIcon',
                    variant: 'danger',
                  },
                })
              }
            })
      }
    },
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName || 'first-card'].showLoading = false
      }, 1000)
    },
  },
  beforeDestroy() {
    store.commit('SALON_LISTESI_ACTION', 'remove')
    store.commit('SALON_TURLERI_ACTION', 'remove')
    console.clear()
  },
  created() {
    store.dispatch('salonTuruListesi')
        .then(res => {
          this.salonTuruToplamKayit = Object.keys(res.liste).length
        })
    store.dispatch('salonListesi')
        .then(res => {
          const liste = { ...res }
          delete liste.userToken
          this.salonToplamKayit = Object.keys(liste).length
          if (liste[0]) {
            this.salon.salonTur = liste[0]
          }
        })
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
}
</script>
<style scoped>
.card-header-btn {
  transform: translateY(3px);
}

.ring-icon {
  height: 40px;
  width: 40px;
}

.salon-hover {
  transition: 500ms;
}

.salon-hover:hover {
  transition: 500ms;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
}
</style>
